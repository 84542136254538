<template>
  <b-row>
    <b-col>
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Tạo Người Dùng
          </b-card-title>
          <b-card-sub-title>
            <b-button @click="validationForm" size="sm" variant="success">
            <feather-icon icon="SaveIcon" /> Lưu</b-button>
          </b-card-sub-title>
        </b-card-header>
        <b-card-body>
          <validation-observer ref="createUser">
            <b-row >
          <b-col cols="12">
            <b-form-group
              label="Họ Tên Khách Hàng"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                :name="`fullNameCustomer${form.type}`"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  v-model="form.fullNameCustomer"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Điện Thoại Khách Hàng"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    :name="`phone_number${form.type}`"
                    rules="required"
                    :customMessages="{['required']: 'Không được bỏ trống'}"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend
                        is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="hi-number"
                        v-model="form.phoneCustomer"
                        type="number"
                        placeholder="Số điện thoại chính"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="`phone_number2${form.type}`"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="hi-number"
                        v-model="form.phoneCustomer2"
                        type="number"
                        placeholder="Số điện thoại phụ"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ngày Sinh Khách Hàng"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="`birthdayCustomer${form.type}`"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                    <b-form-datepicker
                      placeholder="Ngày sinh"
                      v-model="form.birthdayCustomer"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="`genderCustomer${form.type}`"
                    :customMessages="{['required']: 'Không được để trống'}"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form.genderCustomer"
                      :options="genderOptions"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                :name="`Email${form.type}`"
                :customMessages="{['required']: 'Không được để trống'}"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="form.emailCustomer"
                    placeholder="Email của khách hàng"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col>
                <b-form-group
                  label="Địa chỉ"
                  label-cols-md="3"
                >
                  <b-row no-gutters style="padding-bottom: 10px">
                      <b-col class="ft-gutters-padding-10px">
                        <validation-provider
                          #default="{ errors }"
                          :name="`Province${form.type}`"
                          rules="required"
                          :customMessages="{['required']: 'Bạn Phải Chọn tỉnh thành'}"
                        >
                          <b-form-select
                            v-model="form.province"
                            :options="getProvinces"
                            @change="selectedProvince"
                            :state="errors.length > 0 ? false:null"
                          />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                    <b-col class="ft-gutters-padding-10px">
                      <validation-provider
                        #default="{ errors }"
                        :name="`districts${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Bạn phải chọn quận huyện'}"
                      >
                        <b-form-select
                          v-model="form.district"
                          :options="address.districts"
                          @change="selectedDistrict"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="`wards${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Bạn phải chọn phường xã'}"
                      >
                        <b-form-select
                          v-model="form.ward"
                          :options="address.wards"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="`Street${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Không được để trống'}"
                      >
                        <b-form-textarea 
                            v-model="form.street" 
                            :state="errors.length > 0 ? false:null"
                            placeholder="Đường, ngõ, xóm thôn ...."
                          />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Quốc Gia"
              label-cols-md="3"
            >
              <b-form-select
                v-model="form.country" :options="countryCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import CountryCode from '@/@core/app-config/countryCode'
import PcVn from 'pc-vn'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BInputGroupPrepend, BInputGroup, 
  BCardSubTitle, BCardHeader, BCardTitle, BCardBody, BFormInput, BFormDatepicker, BFormTextarea, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption, BFormCheckbox 
} from 'bootstrap-vue'
import { required, email } from '@validations'
import CreateCustomer from '../order/components/forms/createCustomer.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import constants from '@/@core/app-config/constants'

export default {
  mounted() {
    document.title = 'Tạo mới Người Dùng | ' + this.$route.meta.pageTitle
  },
  props: ['id'],
  components: {
    CountryCode,
    PcVn,
    BInputGroupPrepend,
    BInputGroup, 
    BCardSubTitle,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    CreateCustomer,
    FeatherIcon
},
  data() {
    return {
      address: {
        provinces: [{ text: 'Tỉnh / Thành', value: null }],
        districts: [{ text: 'Quận / Huyện', value: null }],
        wards: [{ text: 'Thị xã', value: null }],
      },
      form: {
        full_name: '',
        address: '',
        gender: '',
        phone_number: '',
        orderphone: '',
        birthday: '',
        user_email: '',
        country: '',
        uid: '',
        note: '',
        groups: [],
        status: true, // trang thái
      },
      required,
      email,
    }
  },
  computed: {
    countryCode() {
      return CountryCode.map((item, k) => {
        return {
          text: item.name,
          value: item.code,
        }
      })
    },
    getProvinces() {
      const provinces = PcVn.getProvinces()
      return [this.address.provinces[0], ...provinces.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
    genderOptions() {
      return constants.genderOptions
    },
  },
  methods: {
    validationForm() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },
}
</script>