<template>
  <app-collapse accordion>
    <app-collapse-item
      :isVisible="form.is_collapse"
      :headerStyle="'background-color: ' + (form.type === 'parent' ? '#fcc603' : '#d2d6de')"
    >
      <template slot="header">
        <div class="d-flex align-items-center">
          <feather-icon
            size="24"
            class="mr-1"
            icon="UserIcon"
          />
          <b-card-title>
            {{ form.type === 'parent' ? 'Thêm Phụ Huynh' : 'Thêm Học Viên' }}
          </b-card-title>
        </div>
      </template>
      <div class="ft-filter-form">
        <b-row >
          <b-col cols="12" v-if="form.type !== 'parent'">
            <b-form-group
              label-cols-md="3"
            >
              <b-row>
                <b-col>
                  <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    v-model="form.parent"
                    @change="typeUser($event, form.type, t)"
                  >
                    Thêm Phụ Huynh
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Thông tin"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col class="ft-gutters-padding-10px" v-if="form.type !== 'parent'">
                  <vue-select
                    v-model="form.codeReg"
                    placeholder="Mã Đăng Ký vd 123"
                    @search="fetchMaDangKyOptions"
                    :options="optionMaDangKy"
                    @option:selected="changeMaDangKy"
                  />
                </b-col>
                <b-col>
                  <b-form-input
                    v-model="form.codeCustomer"
                    placeholder="Mã Khách Hàng"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Họ Tên Khách Hàng"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                :name="`fullNameCustomer${form.type}`"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  v-model="form.fullNameCustomer"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Điện Thoại Khách Hàng"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    :name="`phone_number${form.type}`"
                    rules="required"
                    :customMessages="{['required']: 'Không được bỏ trống'}"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend
                        is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="hi-number"
                        v-model="form.phoneCustomer"
                        type="number"
                        placeholder="Số điện thoại chính"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="`phone_number2${form.type}`"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="hi-number"
                        v-model="form.phoneCustomer2"
                        type="number"
                        placeholder="Số điện thoại phụ"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ngày Sinh Khách Hàng"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="`birthdayCustomer${form.type}`"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                    <b-form-datepicker
                      placeholder="Ngày sinh"
                      v-model="form.birthdayCustomer"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="`genderCustomer${form.type}`"
                    :customMessages="{['required']: 'Không được để trống'}"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form.genderCustomer"
                      :options="genderOptions"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                :name="`Email${form.type}`"
                :customMessages="{['required']: 'Không được để trống'}"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="form.emailCustomer"
                    placeholder="Email của khách hàng"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="form.type === 'parent'">
            <b-form-group
              label="Họ Tên Phụ Huynh"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="fullname_teacher"
                rules="required"
                :name="`fullname_teacher${form.type}`"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  v-model="form.fullNameParent"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12"  v-if="form.type === 'parent'">
            <b-form-group
              label="Email Phụ Huynh"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors}"
                name="emailParent"
                rules="required"
                :name="`emailParent${form.type}`"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="form.emailParent"
                    placeholder="Email của phụ huynh"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12"  v-if="form.type === 'parent'">
            <b-form-group
              label="Số Điện Thoại Phụ Huynh"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors}"
                    name="emailParent"
                    rules="required"
                    :name="`phoneParent${form.type}`"
                    :customMessages="{['required']: 'Không được bỏ trống'}"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="form.phoneParent" placeholder="Số điện thoại 1"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="form.phoneParent2" placeholder="Số điện thoại 2"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col>
                <b-form-group
                  label="Địa chỉ"
                  label-cols-md="3"
                >
                  <b-row no-gutters style="padding-bottom: 10px">
                      <b-col class="ft-gutters-padding-10px">
                        <validation-provider
                          #default="{ errors }"
                          :name="`Province${form.type}`"
                          rules="required"
                          :customMessages="{['required']: 'Bạn Phải Chọn tỉnh thành'}"
                        >
                          <b-form-select
                            v-model="form.province"
                            :options="getProvinces"
                            @change="selectedProvince"
                            :state="errors.length > 0 ? false:null"
                          />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                    <b-col class="ft-gutters-padding-10px">
                      <validation-provider
                        #default="{ errors }"
                        :name="`districts${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Bạn phải chọn quận huyện'}"
                      >
                        <b-form-select
                          v-model="form.district"
                          :options="address.districts"
                          @change="selectedDistrict"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="`wards${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Bạn phải chọn phường xã'}"
                      >
                        <b-form-select
                          v-model="form.ward"
                          :options="address.wards"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        :name="`Street${form.type}`"
                        rules="required"
                        :customMessages="{['required']: 'Không được để trống'}"
                      >
                        <b-form-textarea 
                            v-model="form.street" 
                            :state="errors.length > 0 ? false:null"
                            placeholder="Đường, ngõ, xóm thôn ...."
                          />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Quốc Gia"
              label-cols-md="3"
            >
              <b-form-select
                v-model="form.country" :options="countryCode"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ghi Chú"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col class="ft-gutters-padding-10px">
                  <b-form-textarea v-model="form.note1" placeholder="Ghi chú 1" max-rows="10" />
                </b-col>
                <b-col>
                  <b-form-textarea v-model="form.note2" placeholder="Ghi chú 2" max-rows="10" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="ID Mạng Xã Hội"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    :name="`post_fb_id${form.type}`"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                      <b-form-input v-model="form.post_fb_id" placeholder="ID BÀI POST"/>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-col>
                <b-col class="ft-gutters-padding-10px">
                  <b-form-input v-model="form.fanpage_fb_id" placeholder="ID FAN PAGE"/>
                </b-col>
                <b-col class="ft-gutters-padding-10px">
                  <b-form-input v-model="form.student_fb_id" placeholder="ID FB HỌC SINH HỌC VIÊN" />
                </b-col>
                <b-col class="ft-gutters-padding-10px">
                  <b-form-input v-model="form.parent_fb_id" placeholder="ID FB PHỤ HUYNH"/>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import PcVn from 'pc-vn'
import {BFormCheckbox , BInputGroupPrepend, BCardTitle, BInputGroup, BFormDatepicker, BFormSelect, BFormTextarea, BRow, BCol, BFormGroup, BFormInput, } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

import CountryCode from '@/@core/app-config/countryCode'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VueSelect } from 'vue-select'
import { required, email } from '@validations'
// eslint-disable-next-line import/extensions
import constants from '@/@core/app-config/constants'

export default {
  props: {
    typeForm: {
      type: String,
    },
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      optionMaDangKy: [],
      required,
      email,
      address: {
        provinces: [{ text: 'Tỉnh / Thành', value: null }],
        districts: [{ text: 'Quận / Huyện', value: null }],
        wards: [{ text: 'Thị xã', value: null }],
      },
      reg_code: [],
    }
  },
  components: {
    VueSelect,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    BInputGroupPrepend,
    BCardTitle,
    BFormTextarea,
    ValidationProvider,
    BInputGroup,
    BFormDatepicker,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  computed: {
    countryCode() {
      return CountryCode.map((item, k) => {
        return {
          text: item.name,
          value: item.code,
        }
      })
    },
    getProvinces() {
      const provinces = PcVn.getProvinces()
      return [this.address.provinces[0], ...provinces.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
    genderOptions() {
      return constants.genderOptions
    },
  },
  methods: {
    fetchMaDangKyOptions(search, loading) {
      // console.log('fetch ma dang ky', search, loading);
      // fetch
      this.$http.post('/code_reg', { q: search})
        .then(({ data }) => {
          if (data.success) {
            this.optionMaDangKy = data.data
          }
        })
    },
    changeMaDangKy(e) {
      // fetch mã đăng ký
      this.$http.post('/customers', { id: e })
        .then(({ data }) => {
          if (data.success) {
            this.form = data.data
            this.$emit('change-code-reg', e)
          }
        })
    },
    onChange() {
      this.$emit('on-form-change', this.form)
    },
    typeUser(e) {
      this.form.parent = e
      this.$emit('type-user', e)
    },
    selectedProvince(e) {
      const list = PcVn.getDistrictsByProvinceCode(e)
      this.form.district = null// về mặc đinh
      this.address.districts = [this.address.districts[0], ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
      this.onChange()
    },
    selectedDistrict(e) {
      const list = PcVn.getWardsByDistrictCode(e)
      this.form.ward = null// về mặc đinh
      this.address.wards = [this.address.wards[0], ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
      this.onChange()
    },
  },
}
</script>
<style>
.ft-gutters-padding-10px {
  padding-right: 10px!important;
}

</style>
<style lang="scss">
  .ft-filter-form {
    margin-top: 1.5rem;

  }
</style>